<template>
  <div class="content-wrapper">
    <T3BackendLayout
      v-if="pageData?.content"
      :name="backendLayout"
      :content="pageData.content"
    />
  </div>
</template>

<script setup lang="ts">
import { useHead, useMetaData, useT3Page } from '#imports'

const { backendLayout, pageData } = await useT3Page()

const { data } = useMetaData()
useHead(data)
</script>
